import { REPORT_FILTER_ACTION_TYPE } from "../actions-type";

const initialState = {
  reportDateSelectedOption: "",
  reportChooseDate: "",
  reportMonthFilter: "",
};

export const reportFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_FILTER_ACTION_TYPE.GET_REPORT_CHOOSE_DATE_FILTER:
      return {
        ...state,
        reportChooseDate: action.payload?.reportChooseDate,
        reportMonthFilter: "",
      };
    case REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER:
      return {
        ...state,
        financeChooseDate: "",
        financeMonthsFilter: action.payload.financeMonthsFilter,
      };
    case REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION:
      return {
        ...state,
        financeDateSelectedOption: action.payload,
      };

    default:
      return state;
  }
};
