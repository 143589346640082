import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REPORT_FILTER_ACTION_TYPE } from "../../../../redux/actions-type";
import DateRangeModal from "../../../../globalComponents/Modals/DateRangeModal/DateRangeModal";

const StatBox = () => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const { reportDateSelectedOption } = useSelector(
    (state) => state.reportDateFilter
  );
  const data = [
    { key: 1, name: "Cari ay" },
    { key: 3, name: "Son 3 ay" },
    { key: 6, name: "Son 6 ay" },
    { key: 12, name: "İllik" },
    { key: "", name: "Tarix seç" },
  ];

  const dispatch = useDispatch();

  const applyFilter = (startDate = "", endDate = "") => {
    //   getAllDataByDateRange(startDate, endDate);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_CHOOSE_DATE_FILTER,
      payload: { financeChooseDate: { startDate, endDate } },
    });
    setOpenCalendar(false);
  };

  const applyMonthFilter = (option) => {
    //   getAllDataByMonth(option.key);
    setOpenCalendar(false);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER,
      payload: { reportMonthFilter: option.key },
    });
  };

  const selectOption = (option) => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION,
      payload: option,
    });
    if (option.name === "Tarix seç") {
      setOpenCalendar(!openCalendar);
    } else {
      applyMonthFilter(option);
    }
  };

  useEffect(() => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION,
      payload: {
        key: 1,
        name: "Cari ay",
      },
    });
    return () => {
      dispatch({
        type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER,
        payload: { financeMonthsFilter: "" },
      });
    };
  }, []);

  const statBoxCard = [
    {
      id: 1,
      text: "Aktiv müqavilə həcmi",
      value: `30000 AZN`,
      color: "blue",
    },
    {
      id: 2,
      text: "Aktiv gecikmə həcmi",
      value: `30000 AZN`,
      color: "red",
    },
    {
      id: 3,
      text: "Dondurdu/Dayandırdı",
      value: `30000 AZN`,
      color: "orange",
    },
    {
      id: 4,
      text: "Gözləyən tələbə həcmi",
      value: `30000 AZN`,
      color: "green",
    },
  ];

  return (
    <div className="stat-box">
      <div className="stat-date">
        <div className="finance-date-filter">
          {data.map((item, index) => (
            <div
              key={index}
              onClick={() => selectOption(item)}
              className={`content-box ${
                reportDateSelectedOption.key === item.key ? "active" : ""
              }`}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className="stat-box-cards">
        {statBoxCard?.map((card) => (
          <div
            className={`stat-card ${
              card.color === "blue"
                ? "blue"
                : card.color === "red"
                ? "red"
                : card.color === "orange"
                ? "orange"
                : "green"
            } `}
            key={card.id}
          >
            <h3>{card.value}</h3>
            <span>{card.text}</span>
          </div>
        ))}
      </div>

      {openCalendar && (
        <DateRangeModal
          applyFilter={applyFilter}
          setOpenCalendar={setOpenCalendar}
        />
      )}
    </div>
  );
};

export default StatBox;
