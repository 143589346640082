import React, { useEffect, useState } from "react";
import { REPORT_FILTER_ACTION_TYPE } from "../../../../redux/actions-type";
import { useDispatch, useSelector } from "react-redux";

const StudentsList = () => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const { reportDateSelectedOption } = useSelector(
    (state) => state.reportDateFilter
  );
  const data = [
    { key: 1, name: "Cari ay" },
    { key: 3, name: "Son 3 ay" },
    { key: 6, name: "Son 6 ay" },
    { key: 12, name: "İllik" },
    { key: "", name: "Tarix seç" },
  ];

  const studentList = [
    {
      id: 1,
      name: "Aktiv tələbələr",
      count: 10,
      price: "370000",
    },
    {
      id: 2,
      name: "Borclu davam edir",
      count: 10,
      price: "370000",
    },
    {
      id: 3,
      name: "Borclu məzun",
      count: 10,
      price: "370000",
    },
    {
      id: 4,
      name: "Gözləmədə",
      count: 10,
      price: "370000",
    },
    {
      id: 5,
      name: "Dayandırdı",
      count: 10,
      price: "370000",
    },
    {
      id: 6,
      name: "Dondurdu",
      count: 10,
      price: "370000",
    },
    {
      id: 7,
      name: "Borclu dayandırdı",
      count: 10,
      price: "370000",
    },
    {
      id: 8,
      name: "Borclu dondurdu",
      count: 10,
      price: "370000",
    },
  ];

  const dispatch = useDispatch();

  const applyFilter = (startDate = "", endDate = "") => {
    //   getAllDataByDateRange(startDate, endDate);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_CHOOSE_DATE_FILTER,
      payload: { financeChooseDate: { startDate, endDate } },
    });
    setOpenCalendar(false);
  };

  const applyMonthFilter = (option) => {
    //   getAllDataByMonth(option.key);
    setOpenCalendar(false);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER,
      payload: { reportMonthFilter: option.key },
    });
  };

  const selectOption = (option) => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION,
      payload: option,
    });
    if (option.name === "Tarix seç") {
      setOpenCalendar(!openCalendar);
    } else {
      applyMonthFilter(option);
    }
  };

  useEffect(() => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION,
      payload: {
        key: 1,
        name: "Cari ay",
      },
    });
    return () => {
      dispatch({
        type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER,
        payload: { financeMonthsFilter: "" },
      });
    };
  }, []);
  return (
    <div className="student-list-card">
      <div className="finance-date-filter">
        {data.map((item, index) => (
          <div
            key={index}
            onClick={() => selectOption(item)}
            className={`content-box ${
              reportDateSelectedOption.key === item.key ? "active" : ""
            }`}
          >
            {item.name}
          </div>
        ))}
      </div>

      <table className="status-list-cards">
        <thead>
          <tr>
            <th>Ad</th>
            <th>Say</th>
            <th>Qiymət</th>
          </tr>
        </thead>
        <tbody>
          {studentList?.map((group) => (
            <tr key={group.id}>
              <td>{group.name}</td>
              <td>{group.count}</td>
              <td>{group.price} AZN</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentsList;
