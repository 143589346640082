import React from "react";

const Groups = () => {
  const data = [
    {
      id: 1,
      date: "17.02.2025",
      name: "DM1026",
      count: 10,
    },
    {
      id: 2,
      date: "17.02.2025",
      name: "DM1026",
      count: 10,
    },
    {
      id: 3,
      date: "17.02.2025",
      name: "DM1026",
      count: 10,
    },
    {
      id: 4,
      date: "17.02.2025",
      name: "DM1026",
      count: 10,
    },
    {
      id: 5,
      date: "17.02.2025",
      name: "DM1026",
      count: 10,
    },
    {
      id: 6,
      date: "17.02.2025",
      name: "DM1026",
      count: 10,
    },
    
  ];
  return (
    <div className="report-groups-card">
      <div className="group-header">
        <h2>Yığılan qruplar</h2>
      </div>
      <div className="group-cards">
        {data?.map((group) => (
          <div key={group.id} className="group-card" >
            <span>{group.date}</span>
            <span>{group.name}</span>
            <span>{group.count} tələbə</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Groups;
