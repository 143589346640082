import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REPORT_FILTER_ACTION_TYPE } from "../../../../redux/actions-type";
import DateRangeModal from "../../../../globalComponents/Modals/DateRangeModal/DateRangeModal";
const SalesDivision = () => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const { reportDateSelectedOption } = useSelector(
    (state) => state.reportDateFilter
  );
  const data = [
    { key: 1, name: "Cari ay" },
    { key: 3, name: "Son 3 ay" },
    { key: 6, name: "Son 6 ay" },
    { key: 12, name: "İllik" },
    { key: "", name: "Tarix seç" },
  ];

  const dispatch = useDispatch();

  const applyFilter = (startDate = "", endDate = "") => {
    //   getAllDataByDateRange(startDate, endDate);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_CHOOSE_DATE_FILTER,
      payload: { financeChooseDate: { startDate, endDate } },
    });
    setOpenCalendar(false);
  };

  const applyMonthFilter = (option) => {
    //   getAllDataByMonth(option.key);
    setOpenCalendar(false);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER,
      payload: { reportMonthFilter: option.key },
    });
  };

  const selectOption = (option) => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION,
      payload: option,
    });
    if (option.name === "Tarix seç") {
      setOpenCalendar(!openCalendar);
    } else {
      applyMonthFilter(option);
    }
  };

  useEffect(() => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_DATE_SELECTED_OPTION,
      payload: {
        key: 1,
        name: "Cari ay",
      },
    });
    return () => {
      dispatch({
        type: REPORT_FILTER_ACTION_TYPE.GET_REPORT_MONTHS_FILTER,
        payload: { financeMonthsFilter: "" },
      });
    };
  }, []);
  const salesData = [
    {
      id: 1,
      name: "B2B",
      count: 380,
    },
    {
      id: 2,
      name: "B2C",
      count: 670,
    },
    {
      id: 3,
      name: "B2G",
      count: 3,
    },
  ];
  return (
    <div className="lesson-remainder sales division ">
      <img src="/" alt="/" />
      <h2>Satış bölgüsü</h2>
      <div className="students-remainder">
        <div className="finance-date-filter">
          {data.map((item, index) => (
            <div
              key={index}
              onClick={() => selectOption(item)}
              className={`content-box ${
                reportDateSelectedOption.key === item.key ? "active" : ""
              }`}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="sales-report-cards">
          {salesData?.map((student) => (
            <div key={student.id} className="sales-report-card">
              <div className={"report-type division"}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.6492 11.2501L12.7904 6.53852L13.8346 5.46167L20.5774 12.0001L13.8346 18.5385L12.7904 17.4617L17.6492 12.7501H3V11.2501H17.6492Z"
                      fill="white"
                    ></path>{" "}
                  </g>
                </svg>
                <h4>{student.name}</h4>
              </div>
              <div className={"report-type  count "}>
                <h4>{student.count} </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
      {openCalendar && (
        <DateRangeModal
          applyFilter={applyFilter}
          setOpenCalendar={setOpenCalendar}
        />
      )}
    </div>
  );
};

export default SalesDivision;
