const CurrentGroup = () => {
  const studentList = [
    {
      id: 1,
      name: "G1021",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"
    },
    {
      id: 2,
      name: "Borclu davam edir",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
    {
      id: 3,
      name: "Borclu məzun",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
    {
      id: 4,
      name: "Gözləmədə",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
    {
      id: 5,
      name: "Dayandırdı",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
    {
      id: 6,
      name: "Dondurdu",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
    {
      id: 7,
      name: "Borclu dayandırdı",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
    {
      id: 8,
      name: "Borclu dondurdu",
      fullPaymentTotalSum: "370000",
      partPaymentTotalSum:"370000",
      totalRest:"37000"


    },
  ];

  return (
    <div className="current-group-card">
      <table className="status-list-cards">
        <thead>
          <tr>
            <th>Mövcud qruplar</th>
            <th>Tam ödəniş</th>
            <th>Hissəli ödəniş</th>
            <th>Növ.ayl qalıq</th>
          </tr>
        </thead>
        <tbody>
          {studentList?.map((group) => (
            <tr key={group.id}>
              <td>{group.name}</td>
              <td>{group.fullPaymentTotalSum} AZN</td>
              <td>{group.partPaymentTotalSum} AZN</td>
              <td>{group.totalRest} AZN</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CurrentGroup;
