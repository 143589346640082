import React from "react";
import { ReactComponent as ActiveStudentsIcon } from "../../../../assets/icons/dashboard/students-svgrepo-com.svg";
const ActiveStudentsCard = () => {
  const data = [
    {
      id: 1,
      name: "DM",
      studentCount: 10,
    },
    {
      id: 2,
      name: "GD",
      studentCount: 12,
    },
    {
      id: 3,
      name: "DA",
      studentCount: 30,
    },
    {
      id: 4,
      name: "3D",
      studentCount: 10,
    },
    
  ];
  return (
    <div className="active-students-card">
      <h2>Aktiv Tələbə</h2>
      <div className="student-count">
        <h4>135</h4>
        <ActiveStudentsIcon/>
      </div>
      <div className="student-count-for-lessons">
        {data?.map((lesson) => (
          <div key={lesson.id} className="student-for-lesson" >
            <h4>{lesson.name}</h4>
            <span>{lesson.studentCount} Tələbə </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveStudentsCard;
