import React from "react";

const LessonRemainder = () => {
  const data = [
    {
      id: 1,
      name: "Emil İsmayılov",
      count: 3,
      date: "cari ay",
    },
    {
      id: 2,
      name: "Şahlar Dəmirli",
      count: 9,
      date: "cari ay",
    },
    {
      id: 3,
      name: "Emil İsmayılov",
      count: 3,
      date: "cari ay",
    },
    {
      id: 4,
      name: "Emil İsmayılov",
      count: 3,
      date: "cari ay",
    },
    {
      id: 5,
      name: "Emil İsmayılov",
      count: 3,
      date: "cari ay",
    },
  ];
  return (
    <div className="lesson-remainder">
      <img src="/" alt="/" />
      <h2>Əvəz dərs qalığı</h2>
      <div className="students-remainder">
        {data?.map((student) => (
          <div key={student.id} className="student-remainder">
            <span>{student.name}</span>
            <span>-{student.count} dərs</span>
            <span>{student.date}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LessonRemainder;
