// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-amount{
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.payment-amount .content-box{
    padding: 6px;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    width: 30%;
}


.payment-amount .content-box .left{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 6px;
    margin-right: 12px;
}


.payment-amount .content-box .right{
    width: 100%;
}


.payment-amount .content-box .right .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-amount .content-box .right .top .title {
    font-size: 16px;
    font-weight: 600;
    color: var(--neutrals-600);
}

.payment-amount .content-box .right   .amount{
    color: var(--neutrals-900);
    font-size: 20px;
    font-weight: 700;
}

`, "",{"version":3,"sources":["webpack://./src/globalComponents/GlobalHead/PaymentResult/paymentResult.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;AAElC;;;AAGA;IACI,YAAY;IACZ,sBAAsB;IACtB,gDAAgD;IAChD,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;AACd;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".payment-amount{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n}\n\n\n.payment-amount .content-box{\n    padding: 6px;\n    background-color: #fff;\n    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    position: relative;\n    width: 30%;\n}\n\n\n.payment-amount .content-box .left{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 10px;\n    border-radius: 6px;\n    margin-right: 12px;\n}\n\n\n.payment-amount .content-box .right{\n    width: 100%;\n}\n\n\n.payment-amount .content-box .right .top{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.payment-amount .content-box .right .top .title {\n    font-size: 16px;\n    font-weight: 600;\n    color: var(--neutrals-600);\n}\n\n.payment-amount .content-box .right   .amount{\n    color: var(--neutrals-900);\n    font-size: 20px;\n    font-weight: 700;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
