import React, { useState } from "react";

const Sales = () => {
  const [startDate, setStartDate] = useState(null || "");
  const [endDate, setEndDate] = useState(null || "");

  const data = [
    {
      id: 1,
      name: "DM",
      studentCount: 5,
    },
    {
      id: 2,
      name: "DA",
      studentCount: 12,
    },
    {
      id: 3,
      name: "PO",
      studentCount: 1,
    },
    {
      id: 4,
      name: "3D",
      studentCount: 15,
    },
  ];
  return (
    <div className="active-students-card">
      <h2>Satış</h2>
      <div className="entry-datepick">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <div className="student-count-for-lessons">
        {data?.map((lesson) => (
          <div key={lesson.id} className="student-for-payment">
            <h4>{lesson.name}</h4>
            <span>+{lesson.studentCount} tələbə </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sales;
