import React, { useState } from "react";
import { ReactComponent as MoneyIcon } from "../../../../assets/icons/report/money.svg";

const Entry = () => {
  const [startDate, setStartDate] = useState(null || "");
  const [endDate, setEndDate] = useState(null || "");

  const data = [
    {
      id: 1,
      name: "Tələbə aylıq ödəniş",
      studentCount: 470,
    },
    {
      id: 2,
      name: "Tələbə tam ödəniş",
      studentCount: 3200,
    },
    {
      id: 3,
      name: "Gecikmə ödənişi",
      studentCount: 800,
    },
    {
      id: 4,
      name: "Yeni qeydiyyat",
      studentCount: 100,
    },
    
  ];
  return (
    <div className="active-students-card">
      <h2>Daxil olmalar</h2>
      <div className="entry-header" >

      <div className="entry-datepick">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <MoneyIcon/>
      </div>

      <div className="student-count-for-lessons">
        {data?.map((lesson) => (
          <div key={lesson.id} className="student-for-payment">
            <h4>{lesson.name}</h4>
            <span>+{lesson.studentCount} AZN </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Entry;
