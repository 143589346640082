import React from "react";
import StatBox from "./components/StatBox/StatBox";
import ActiveStudentsCard from "./components/ActiveStudentsCard/ActiveStudentsCard";
import Entry from "./components/Entry/Entry";
import Sales from "./components/Sales/Sales";
import Groups from "./components/Groups/Groups";
import SalesReport from "./components/SalesReport/SalesReport";
import LessonRemainder from "./components/LessonRemainder/LessonRemainder";
import SalesDivision from "./components/SalesDivision/SalesDivision";
import StudentsList from "./components/StudentsList/StudentsList";
import CurrentGroup from "./components/CurrentGroup/CurrentGroup";

const ReportPage = () => {
  return (
    <div className="report-page">
      <div className="container">
        <div className="report-main">
          <div className="report-top">
            <div className="report-top-left">
              <StatBox />
              <div className="report-top-students-data">
                <div className="report-active-students">
                  <ActiveStudentsCard />
                </div>
                <div className="report-sales-and-groups">
                  <div className="report-sales-and-entry">
                    <div className="report-entry">
                      <Entry />
                    </div>
                    <div className="report-sales">
                      <Sales />
                    </div>
                  </div>
                  <div className="report-groups">
                    <Groups />
                  </div>
                </div>
              </div>
            </div>
            <div className="report-top-right">
              <LessonRemainder />
              <SalesReport />
            </div>
          </div>
          <div className="report-bottom">
            <div className="report-bottom-left">
              <div className="students-list">
                <StudentsList />
              </div>
              <div className="current-groups">
                <CurrentGroup />
              </div>
            </div>
            <div className="report-bottom-right">
              <SalesDivision />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
